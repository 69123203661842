<template>
  <div>
    <b-card-group deck>
      <b-card
          :header="is_edit?'SỬA CHIẾN DỊCH THIỆN NGUYỆN':'TẠO CHIẾN DỊCH THIỆN NGUYỆN'"
          header-tag="header"
      >
        <b-form  @submit="saveForm">
          <h2 class="title-block">THÔNG TIN CHÍNH</h2>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <b-form-group
                    id="input-dk-2"
                    label="Tên chiến dịch (*)"
                    label-for="ms-title"
                    class="col-md-12"
                >
                  <b-form-input
                      id="ms-title"
                      v-model="form.name"
                      maxlength="191"
                      required
                      placeholder="Tên chiến dịch"
                      :class="['col-md-12',{ 'is-invalid': form.errors.has('name') }]"
                  ></b-form-input>
                  <has-error :form="form" field="name"></has-error>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group
                    id="input-type-gift"
                    label="Chọn đơn vị tổ chức (*)"
                    label-for="gift-type"
                    class="col-md-12"
                >
                  <b-form-select
                      v-model="form.provider_id"
                      lable=""
                      required
                      :class="[{ 'is-invalid': form.errors.has('provider_id') }]"
                      placeholder="-Chọn-"
                  >
                    <option  value="">-chọn-</option>
                    <option v-for="(item ,key) in list_organization" :value="item.id" :key="key">{{item.name}}</option>


                  </b-form-select>
                  <has-error :form="form" field="provider_id"></has-error>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group  class="col-md-12" label="Banner (*):" >
                  <div>
                    <div style="height: 50px;" v-if="form.image">
                      <img
                          style="width: 38px; height: 38px"
                          :src="form.image"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event, 'image')"
                        placeholder="chọn icon"
                        :required="form.image.length > 0?false:true"
                        accept="image/*"
                        type="file"
                        :class="[{ 'is-invalid': form.errors.has('image') }]"
                    ></b-form-file>
                    <has-error :form="form" field="image"></has-error>
                  </div>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group  class="col-md-12" label="Văn bản xác nhận tại trợ:" >
                  <div>

                    <b-form-input
                        id="ms-title"
                        v-model="form.document"
                        maxlength="255"
                        placeholder="https://9pay.vn/tin-tuc/dieu-khoan-hop-tac"
                        :class="['col-md-12',{ 'is-invalid': form.errors.has('document') }]"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">

                <b-form-group
                    id="input-group-4"
                    label="Thời gian bắt đầu diễn ra (*)"
                    label-for="dp1"
                    class="col-md-6"
                >
                  <date-picker
                      id="dp1"
                      format="YYYY-MM-DD HH:mm:ss"
                      value-type="format"
                      v-model="form.date_start"
                      type="datetime"
                      placeholder="Chọn thời gian"
                      :input-attr="{required: 'true',name:''}"
                  ></date-picker>
                  <has-error :form="form" field="date_start"></has-error>
                </b-form-group>


                <b-form-group
                    id="input-group-4"
                    label="Thời gian kết thúc (*)"
                    label-for="dp2"
                    class="col-md-6"
                >
                  <date-picker
                      id="dp2"
                      v-model="form.date_end"
                      type="datetime"
                      placeholder="Chọn thời gian"
                      :input-attr="{required: 'true',name:''}"
                      format="YYYY-MM-DD HH:mm:ss"
                      value-type="format"
                  ></date-picker>
                  <has-error :form="form" field="date_end"></has-error>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group
                    id="input-tager-money"
                    label="Số tiền mục tiêu (*)"
                    label-for="gift-type"
                    class="col-md-12"
                >
                  <b-form-input
                      id="ms-title"
                      v-model="form.target_money"
                      maxlength="191"
                      required
                      type="number"
                      placeholder="1.000.000"
                      :class="['col-md-12',{ 'is-invalid': form.errors.has('target_money') }]"
                  ></b-form-input>
                  <has-error :form="form" field="target_money"></has-error>
                </b-form-group>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <b-form-group
                        id="is_allowed_pass"
                        label="Cho phép vượt mục tiêu"
                        label-for="is_allowed_pass_ip"
                        class="col-md-6"
                    >
                      <b-form-checkbox
                          id="is_allowed_pass_ip"
                          v-model="form.is_allowed_pass"
                          :checked="1"
                          :value="1"
                      ></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                        id="is_allowed_pass"
                        label="Không cho phép vượt mục tiêu"
                        label-for="not_allowed_pass_ip"
                        class="col-md-6"
                    >
                      <b-form-checkbox
                          id="not_allowed_pass_ip"
                          v-model="form.is_allowed_pass"
                          :checked="0"
                          :value="0"
                      ></b-form-checkbox>
                    </b-form-group>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="input-group-1"
                  label="Mô tả ngắn(*):"
                  label-for="input-2"
                  required
                  :class="[{ 'is-invalid': form.errors.has('short_description') }]"
              >
                <vue-editor required v-model="form.short_description"></vue-editor>
                <has-error :form="form" field="short_description"></has-error>

              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="input-group-1"
                  label="Thông tin chương trình(*):"
                  label-for="input-2"
                  required
                  :class="[{ 'is-invalid': form.errors.has('description') }]"
              >
                <vue-editor required v-model="form.description"></vue-editor>
                <has-error :form="form" field="description"></has-error>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <b-form-group
                    id="position"
                    label="Vị trí"
                    label-for="input-position"
                    class="col-md-12"
                >
                  <b-form-input
                      id="input-position"
                      v-model="form.position"
                      type="number"
                      placeholder="1"
                      :class="['col-md-12',{ 'is-invalid': form.errors.has('position') }]"
                  ></b-form-input>
                  <has-error :form="form" field="title"></has-error>
                </b-form-group>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <b-form-group
                        id="status"
                        label="Hiển thị"
                        label-for="active_ip"
                        class="col-md-6"
                    >
                      <b-form-checkbox
                          id="active_ip"
                          v-model="form.active"
                          :checked="1"
                          :value="1"
                          required
                      ></b-form-checkbox>

                    </b-form-group>
                    <b-form-group
                        id="inactive"
                        label="Không hiển thị"
                        label-for="un_active_ip"
                        class="col-md-6"
                    >
                      <b-form-checkbox
                          id="un_active_ip"
                          v-model="form.active"
                          :checked="0"
                          :value="0"
                          required
                      ></b-form-checkbox>

                    </b-form-group>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="title-block">CÀI ĐẶT CHỌN NHANH</h2>
          <div class="row">
            <div class="col-md-6" v-if="suggest_money_array.length > 0">
              <div  class="row" v-for="(item,index) in suggest_money_array" :key="index">
                <b-form-group
                    id="money"
                    label="Gói tiền"
                    label-for="money_ip"
                    class="col-md-12"
                >
                  <div class="" style="display:flex;">
                    <b-form-input
                        :key="index"
                        type="number"
                        id="money_ip"
                        v-model="suggest_money_array[index].value"
                        maxlength="191"
                        placeholder="10.000"
                    ></b-form-input>
                    <button class="btn btn-danger " @click="deleteSuggestMoney(index)"><i class="fa fa-trash"></i></button>
                  </div>
                  <button v-if="index === suggest_money_array.length-1 " class="btn btn-info" type="button" @click="addSuggestMoney">+ Thêm lựa chọn</button>
                </b-form-group>

              </div>


            </div>
            <div v-else  class="col-md-6">
              <div class="col-md-12">
                <button  class="btn btn-info" type="button" @click="addSuggestMoney">+ Thêm lựa chọn</button>
              </div>
            </div>

            <div class="col-md-6" v-if="suggest_content_array.length >0">
              <div class="row" v-for="(item,index) in suggest_content_array" :key="item+index">
                <b-form-group
                    id="money"
                    label="Lời nhắn mẫu"
                    label-for="suggest_content"
                    class="col-md-12"
                >
                  <div class="" style="display:flex;">
                    <b-form-input
                        id="suggest_content"
                        v-model="suggest_content_array[index].value"
                        placeholder="Lời nhắn mẫu"
                    ></b-form-input>
                    <button  class="btn btn-danger " @click="deleteSuggestContent(index)"><i class="fa fa-trash"></i></button>
                  </div>
                  <button v-if="index === suggest_content_array.length-1 " class="btn btn-info" type="button" @click="addSuggestContent">+ Thêm lựa chọn</button>
                </b-form-group>

              </div>
            </div>
            <div v-else class="col-md-6">
              <div class="col-md-12">
                <button  class="btn btn-info" type="button" @click="addSuggestContent">+ Thêm lựa chọn</button>
              </div>
            </div>
          </div>

          <div class="">
            <b-button type="submit"  variant="primary">{{
                is_edit ? "Cập nhập" : "Xác nhận"
              }}</b-button>
            <router-link v-if="is_edit" :to="'/charity/campaign-charities'">
              <b-button variant="danger">Hủy bỏ</b-button>
            </router-link>
            <b-button v-else type="reset" variant="danger">Làm mới</b-button>
          </div>
        </b-form>
      </b-card>
    </b-card-group>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from 'vform';
import {
  HasError,
} from 'vform/src/components/bootstrap5'
import { VueEditor } from "vue2-editor";
Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const BackendRepository = RepositoryFactory.get("Backend");
//const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: "FormMission",
  mixins: [Common],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueEditor,
    DatePicker
  },
  data() {
    return {

      form: new Form({
        id: '',
        name: '',
        provider_id: '',
        image: '',
        description: '',
        short_description: '',
        status: 1,
        active: 1,
        date_start: '',
        date_end: '',
        is_allowed_pass: 0,
        position: '',
        target_money: '',
        suggest_money: '',
        suggest_content: '',
        document: '',
      }),

      show: true,
      is_edit: false,
      is_disable: false,
      suggest_content_array: [{value:''}],
      suggest_money_array: [{value:''}],
      list_organization: [],
    };

  },
  computed: {

  },
  watch: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí tổ chức ", route: "/charities/organization" },
      { title: "Thêm tổ chức" },
    ]);
  },
  methods: {
    addSuggestMoney(){
      this.suggest_money_array.push({value:''});
    },
    deleteSuggestMoney(index){
      this.suggest_money_array.splice(index,1);
    },
    addSuggestContent(){
      this.suggest_content_array.push({value:''});
    },
    deleteSuggestContent(index){
      this.suggest_content_array.splice(index,1);
    },
    saveForm () {
      this.form.suggest_content = JSON.stringify(this.suggest_content_array)
      this.form.suggest_money = JSON.stringify(this.suggest_money_array)
       let url = '/charities/campaign/store'
      if (this.is_edit){
         url = '/charities/campaign/update/'+this.form.id;

      }
      this.form.submit('post', url)
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "campaign-charities" });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Vui lòng kiểm tra lại form nhập liệu.",
            });
          })

    },
    async loadCampaign(id) {
      try {
        let response = await BackendRepository.showCampaignCharities(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;

          this.form.fill(data);
          this.form.date_end = data.product_detail.date_end;
          this.form.date_start = data.product_detail.date_start;
          this.form.position = data.product_detail.position;
          this.form.target_money = data.product_detail.target_money;
          this.form.is_allowed_pass = data.product_detail.is_allowed_pass;
          this.form.description = data.product_detail.description;
          this.form.document = data.product_detail.document;
          this.form.short_description = data.description;
          this.form.active = data.active;
          if ( data.product_detail.suggest_content){
            this.suggest_content_array = JSON.parse( data.product_detail.suggest_content)
          }
          if ( data.product_detail.suggest_money){
            this.suggest_money_array = JSON.parse( data.product_detail.suggest_money)
          }

        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    async loadOrganizationList() {
      try {
        let response = await BackendRepository.listOrganization();
        if (response.data.error_code === 0) {
          let data = response.data.data;

          this.list_organization = data;

        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },

    onReset(evt) {
      evt.preventDefault();
      this.form = {
        name: '',
        provider_id: '',
        image: '',
        description: '',
        status: 1,
        active: 1,
        date_start: '',
        date_end: '',
        is_allowed_pass: '',
        position: '',
        target_money: '',
        suggest_money: '',
        suggest_content: '',
      };
      this.suggest_content_array=['']
      this.suggest_money_array=['']

    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.image = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
  },
  created() {
    this.loadOrganizationList();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadCampaign(this.$route.params.id)
    }

  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mx-datepicker{
  width: 100% !important;
}
.mx-datepicker input{
  height: 39px  !important;
}
</style>